import { useContext, useEffect } from "react";
import { Navigate, createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import { AuthContext } from "src/context/auth";
import Dashboardlayout from "src/layouts/DashboardLayout";

const ProtectedRoute = () => {
  const { user, setUser } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { workspace_id } = useParams();
  const params = { redirectUrl: `${location.pathname}${location.search}` };
  const queryString = `?${createSearchParams(params)}`;

  useEffect(() => {
    const getPublicGroupDetails = async () => {
      try {
        const { data: resData } = await apiRequest("get", "/portal/workspaces/:workspace_id/groups/:group_id/pages/:page_id/public", {
          params: {
            workspace_id,
            group_id: searchParams.get("group"),
            page_id: searchParams.get("page"),
          },
        });

        if (resData.status !== 200) {
          logoutRedirect();
        }
      } catch (error) {
        if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") {
          logoutRedirect();
        }
      }
    };
    if (!localStorage.getItem("accessToken") && location?.pathname?.includes("/embed") && workspace_id && searchParams.get("group") && searchParams.get("page")) {
      getPublicGroupDetails();
    }
  }, [location?.pathname, workspace_id, searchParams]);

  useEffect(() => {
    const loadUser = async () => {
      if (user?._id) return;

      if (localStorage.getItem("accessToken")) {
        try {
          const { data: resData } = await apiRequest("post", "/portal/users/self/details", {
            body: {
              token: localStorage.getItem("accessToken"),
              access_token: localStorage.getItem("accessToken"),
            },
          });

          if (resData.status !== 200) {
            logoutRedirect();
          }

          setUser(resData.data);
        } catch (error) {
          if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") {
            logoutRedirect();
          }
        }
      } else {
        if (location?.pathname?.includes("/embed") && workspace_id && searchParams.get("group") && searchParams.get("page")) {
          return <Dashboardlayout publicGroup />;
        } else {
          navigate({
            pathname: "/",
            search: queryString,
          });
        }
      }
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  if (!localStorage.getItem("accessToken")) {
    if (location?.pathname?.includes("/embed") && workspace_id && searchParams.get("group") && searchParams.get("page")) {
      return <Dashboardlayout publicGroup />;
    } else {
      return <Navigate to={`/${queryString}`} />;
    }
  }

  if (window.location.pathname.includes("/embeded/") || window.location.pathname.includes("/embed/items/")) {
    return <Dashboardlayout nested />;
  }

  return <Dashboardlayout />;
};

export default ProtectedRoute;
